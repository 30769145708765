import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ApiService } from 'src/app/core/http/api.service';
import { MemoList } from 'src/app/modules/memos/model/memo.model';
import {
  CloseMemoPreview,
  NavigateToMemoDetail,
} from '../../../store/memo-preview/memo-preview.actions';
import { Store } from '@ngxs/store';
import { MemoPreviewState } from '../../../store/memo-preview/memo-preview.state';
import { featureFlag } from '../../../../environments/environment';
import { ErrorNotification } from 'src/app/store/memo/memo.actions';
import { TranslateService } from '@ngx-translate/core';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-modal-pdf-preview-std4',
  templateUrl: './modal-pdf-preview-std4.component.html',
  styleUrls: ['./modal-pdf-preview-std4.component.scss'],
})
export class ModalPdfPreviewStd4Component
  implements OnInit, OnDestroy
{
  _featureFlag = featureFlag;
  memo: MemoList;
  pdfFile: string;
  showingAttachment: boolean;
  showingViewDetail: boolean;

  title = 'PREVIEW-PDF.MEMO';
  module = 'PREVIEW-PDF.MEMO';
  detailBtnText = 'MEMOS.View memo details';
  detailBtnIconPath: 'assets/images/icons/accessibility/info-circle.svg#info-circle';
  detailBtnClickFn: () => void;

  @Output() viewDetail = new EventEmitter();
  @Output() viewDetailAttachment = new EventEmitter();

  @ViewChild('pdfView', { static: false })
  private pdfView: ElementRef;

  isDocumentLoadingPage: boolean;
  isRefreshPDF = false;
  currentDocumentPage: number;
  totalDocumentPage: number;
  httpHeaders;

  pageWidth: any = {};
  pageHeight: any = {};
  subscription: Subscription[] = [];
  PDF_ICON_WIDTH = 40;

  isRendered = false;
  lastPageRenderTime: Date;
  pageRenderInterval: any;
  latestPageRenderThreshold = 100;

  private translate = inject(TranslateService);
  private spinner = inject(NgxSpinnerService);

  constructor(
    private apiService: ApiService,
    private memoService: MemoService,
    private authenticationService: AuthenticationService,
    private store: Store,
  ) {
    this.httpHeaders = this.authenticationService.httpHeader;
    this.store.select(MemoPreviewState).subscribe({
      next: (res) => {
        this.memo = res.memo;
        this.pdfFile = res.pdfFile;
        this.httpHeaders = this.authenticationService.httpHeader;
        this.showingAttachment = res.showingAttachment;
        this.showingViewDetail = res.showingViewDetail;
        this.title = res.title || this.memo?.subject;
        this.module = res.module || 'PREVIEW-PDF.MEMO';
        this.detailBtnText =
          res.detailBtn?.text || 'MEMOS.View memo details';
        this.detailBtnIconPath =
          res.detailBtn?.iconPath ||
          'assets/images/icons/accessibility/info-circle.svg#info-circle';
        this.detailBtnClickFn = res.detailBtn?.clickFn;
      },
    });
  }

  @HostListener('document:keyup.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('click', ['$event.target'])
  checkClickOutside(target: HTMLDivElement) {
    if (
      target.classList.contains('pdf-preview') ||
      target.classList.contains('ng2-pdf-viewer-container')
    ) {
      this.close();
    }
  }

  ngOnInit(): void {
    this.spinner.show('loadingPdfPreviewStd4');

    if (this.pdfFile) {
      this.isDocumentLoadingPage = true;
    }

    this.isRendered = !this.pdfFile;
  }

  onPdfViewResized(event: any) {
    if (
      this.isRendered &&
      !this.isRefreshPDF &&
      !this.isDocumentLoadingPage
    ) {
      this.isRefreshPDF = true;
      this.isDocumentLoadingPage = true;
      setTimeout(() => {
        this.isRefreshPDF = false;
      }, 20);
    }
  }

  // need to reset isRendered false on manual page number change
  // to prevent flickering
  currentDocumentPageChange(): void {
    this.isRendered = false;
  }

  async onLoadPdfComplete(pdf: PDFDocumentProxy): Promise<void> {
    this.currentDocumentPage = 1;
    this.totalDocumentPage = pdf.numPages;
  }

  async pageRendered(event: any) {
    this.pageWidth[event.pageNumber] = Math.floor(event.source.width);
    this.pageHeight[event.pageNumber] = Math.floor(
      event.source.height,
    );
    this.isDocumentLoadingPage = false;

    this.lastPageRenderTime = new Date();

    if (!this.isRendered) {
      this.waitAllPagesRender();
    }
  }

  /**
   * for pdfs with multiple pages, pageRendered() is called for visible pages and +1 page.
   * if ng2-pdf-viewer is visible before all these pages are rendered,
   * user will see screen flickering
   * this method triggers the ng2-pdf-viewer visibility flag
   * after making sure all necessary renderings has been done
   */
  waitAllPagesRender(): void {
    this.pageRenderInterval = setInterval(() => {
      this.checkRenderCompletion();
    }, 10);
  }

  checkRenderCompletion(): void {
    const now = new Date();
    const difference =
      now.getTime() - this.lastPageRenderTime.getTime();
    if (difference > this.latestPageRenderThreshold) {
      this.clearPageRenderInterval();
      this.isRendered = true;
    }
  }

  clearPageRenderInterval(): void {
    this.pageRenderInterval && clearInterval(this.pageRenderInterval);
    delete this.pageRenderInterval;
  }

  close() {
    this.store.dispatch(new CloseMemoPreview());
  }

  goToDetail() {
    if (this.detailBtnClickFn) {
      this.store.dispatch(new CloseMemoPreview());
      this.detailBtnClickFn();
    } else {
      this.store.dispatch(new NavigateToMemoDetail());
    }
  }

  goToAttachment() {
    this.store.dispatch(new NavigateToMemoDetail('attachment'));
  }

  printDocument(): void {
    this.apiService.openMediaUrlInNewWindow(
      this.memo.signed_document,
    );
  }

  downloadDocument(): void {
    this.memoService.downloadMemo(this.memo.id).subscribe({
      next: (res: any) => {
        const blob: Blob = res.type
          ? new Blob([res], { type: res.type })
          : new Blob([res]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.memo.memo_number
          ? this.memo.memo_number.replace(/\./gi, '-')
          : this.translate.instant('MEMOS.DRAFT').toUpperCase();
        link.click();
      },
      error: (error) => {
        this.store.dispatch(new ErrorNotification(error));
      },
    });
  }

  get right() {
    if (this.pdfFile) {
      return (
        (this.pdfView?.nativeElement?.clientWidth -
          this.pageWidth[this.currentDocumentPage]) /
          2 -
        this.PDF_ICON_WIDTH
      );
    } else {
      return Math.min(
        this.pdfView?.nativeElement?.clientWidth * 0.9,
        465,
      );
    }
  }

  ngOnDestroy(): void {
    this.clearPageRenderInterval();
  }
}
