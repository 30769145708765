import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisplayService } from '@core/services/display.service';
import { DeviceType } from '@shared/models/common.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-type-number',
  templateUrl: './input-type-number.component.html',
  styleUrls: ['./input-type-number.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class InputTypeNumberComponent {
  @Input() model: number;
  @Output() modelChange: EventEmitter<number> = new EventEmitter();
  @Input() min: number;
  @Input() max: number;
  deviceType: DeviceType = 'desktop';
  subscription = new Subscription();

  private displayService = inject(DisplayService);

  constructor() {
    this.observeDisplay();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private observeDisplay(): void {
    const subs = this.displayService
      .getBreakpointDeviceObserver()
      .subscribe({
        next: (res) => {
          this.deviceType = res;
        },
      });
    this.subscription.add(subs);
  }

  increment() {
    if (!this.max || (this.max && this.model < this.max)) {
      this.model++;
      this.emit();
    }
  }

  decrement() {
    if (!this.min || (this.min && this.model > this.min)) {
      this.model--;
      this.emit();
    }
  }

  emit() {
    setTimeout(() => {
      this.modelChange.emit(this.model);
    }, 700);
  }
}
