import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { featureFlag } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EContractGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (!featureFlag.econtract_enable) {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
    }

    return featureFlag.econtract_enable;
  }

  // tslint:disable-next-line:max-line-length
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!featureFlag.econtract_enable) {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
    }

    return featureFlag.econtract_enable;
  }
}
