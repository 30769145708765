<div class="pdf-view">
  <div *ngIf="showDownload"
      class="d-none d-md-flex position-relative justify-content-end align-items-center w-100">
    <div class="pointer me-u0 p-1"
        (click)="downloaded.emit()">
      <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'width.px': 13, 'height.px': 13}"></svg-icon>
      <span [style.color]="'hsl(var(--std-color-3))'"
          class="text-decoration-underline ms-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>
  </div>
  <div class="container-pdf-viewer">
    <div #pdfView
        class="pdf-viewer-wrapper"
        [ngClass]="{
          'align-items-center': totalPage === 1
        }">
      <pdf-viewer class="w-100"
          [ngStyle]="{
            height: !isLoading &&
                pdfViewport.renderedHeight && 
                totalPage === 1 && 
                pdfViewport.fit && 
                pdfViewport.renderedHeight < pdfView.clientHeight ?
              pdfViewport.renderedHeight + 'px' :
              '100%'
          }"
          [ngClass]="{
            'opacity-0': isLoading,
            'opacity-100': !isLoading,
            'temp-class-for-adjusting': tempClassForAdjusting,
          }"
          #pdfViewer
          [src]="{
            url: pdfFile,
            httpHeaders: (_featureFlag.media_url_need_authentication ? httpHeaders : undefined)
          }"
          [page]="_page()"
          [autoresize]="true"
          [c-maps-url]="'/assets/cmaps/'"
          [render-text]="true"
          [render-text-mode]="1"
          [original-size]="!pdfViewport.fit"
          [stick-to-page]="false"
          [show-all]="true"
          [show-borders]="false"
          [zoom]="pdfViewport.scale"
          [zoom-scale]="pdfViewport.fit || 'page-fit'"
          (after-load-complete)="onLoadPdfComplete($event)"
          (click)="clickPreview.emit()"
          (pageChange)="_page.set($event)"
          (page-rendered)="onPageRendered($event)">
      </pdf-viewer>
      <app-pdf-page-controller [dropdownPosition]="dropdownPosition"
          [page]="_page()"
          [renderedScale]="pdfViewport.renderedScale"
          [scale]="pdfViewport.fit ?? pdfViewport.scale"
          [style.bottom]="controllerBottomPosition"
          [totalPage]="totalPage"
          (click)="$event.stopPropagation()"
          (pageChange)="toPage($event || 1)"
          (scaleChange)="onControlScaleChange($event)">
      </app-pdf-page-controller>
      <ngx-spinner bdColor="transparent"
          color="#fff"
          name="loadingPdfViewer"
          size="medium"
          type="ball-clip-rotate"
          [fullScreen]="false"
          [showSpinner]="isLoading"
          [zIndex]="2">
      </ngx-spinner>
    </div>
  </div>
</div>