<div class="controller-plate px-u0">
  <div class="container-page-controller d-flex align-items-center pe-base-l6">
    <label class="pe-2">Page</label>
    <svg class="pointer icon-size-res"
        stroke="currentColor"
        height="16"
        width="16"
        viewBox="0 0 24 24"
        (click)="onPrevPage()">
      <use href="assets/images/icons/arrows/chevron-left.svg#chevron-left">
      </use>
    </svg>
    <input class="form-control round-input hide-arrow mx-l11 ms-md-l0 pe-2"
        inputmode="numeric"
        title="Page"
        type="number"
        [(ngModel)]="page"
        (keyup.enter)="onPageSubmit()"
        (blur)="onPageSubmit()">
    <label>/ {{ totalPage }}</label>
    <svg class="ms-md-l0 pointer icon-size-res"
        stroke="currentColor"
        height="16"
        width="16"
        viewBox="0 0 24 24"
        (click)="onNextPage()">
      <use href="assets/images/icons/arrows/chevron-right.svg#chevron-right">
      </use>
    </svg>
  </div>
  <div *ngIf="scaleFeature !== 'hide'"
      class="row container-scale-controller flex-nowrap gx-base-l6 ps-base-l6">
    <div class="col-auto">
      <ng-select bindLabel="label"
          bindValue="value"
          class="std-skin"
          groupBy="scaleType"
          [clearable]="false"
          [disabled]="scaleFeature === 'disable'"
          [dropdownPosition]="dropdownPosition"
          [items]="scaleDropdownChoices"
          [searchable]="false"
          [(ngModel)]="scale"
          (ngModelChange)="onScaleChange($event)">
        <ng-template ng-optgroup-tmp>
          <div class="line"></div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-auto">
      <button class="btn btn-icon text-white"
          title="Zoom Out"
          type="button"
          [disabled]="scaleFeature === 'disable'"
          (click)="changeScale('down')">
        <svg height="16"
            stroke="currentColor"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="16">
          <use href="assets/images/icons/marks/minus.svg#minus"></use>
        </svg>
      </button>
    </div>
    <div class="col-auto">
      <button class="btn btn-icon text-white"
          title="Zoom In"
          type="button"
          [disabled]="scaleFeature === 'disable'"
          (click)="changeScale('up')">
        <svg height="16"
            stroke="currentColor"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="16">
          <use href="assets/images/icons/marks/plus.svg#plus"></use>
        </svg>
      </button>
    </div>
  </div>
</div>
