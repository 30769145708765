<ng-select class="std-skin bubble-dropdown"
    [appendTo]="appendTo"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [class.search-in-dropdown]="showSearchInDropdown"
    [clearable]="clearable"
    [closeOnSelect]="!showCheckbox"
    [loading]="loading"
    [groupBy]="groupBy"
    [groupValue]="getGroupValue"
    [selectableGroup]="true"
    [selectableGroupAsModel]="false"
    [isOpen]="isOpen"
    [items]="items"
    [disabled]="disabled"
    [multiple]="true"
    [notFoundText]="notFoundText | translate"
    [placeholder]="(placeholder || '') | translate"
    [(ngModel)]="selected"
    [searchFn]="searchFn"
    (add)="add.emit($event)"
    (open)="isOpened = true"
    (close)="closed.emit($event); isOpened = false"
    (remove)="remove.emit($event)"
    (ngModelChange)="onValueChange($event)"
    [ngClass]="{ 'is-invalid': invalid }">
  <ng-container *ngIf="showSearchInDropdown || showAllSelection">
    <ng-template ng-header-tmp
        let-searchTerm="searchTerm">
      <ng-container *ngIf="showSearchInDropdown">
        <div class="input-icon mx-l6 mt-l8 mb-l12"
            id="searchInputContainer">
          <svg class="icon-prepend text-gray-pantone"
              height="24"
              stroke="currentColor"
              stroke-width="1.5"
              width="24"
              viewBox="0 0 24 24">
            <use href="assets/images/icons/objects/search.svg#search"></use>
          </svg>
          <input class="form-control shadow-none round-input"
              placeholder="{{ 'COMMON.Search' | translate }}..."
              type="text"
              [(ngModel)]="inDropdownSearch"
              (ngModelChange)="onInDropdownSearch($event)">
        </div>
      </ng-container>
      <ng-container *ngIf="showAllSelection && !searchTerm && items.length > 0">
        <div class="d-flex align-items-center fw-medium text-black-deep all-checkbox"
            (click)="allCheckbox.click()">
          <input #allCheckbox
              class="form-control checkbox-default default-size me-base-l8"
              id="item-all"
              title="{{ allSelectionText | translate }}"
              type="checkbox"
              [ngModel]="isAllChecked"
              (ngModelChange)="toggleAllSelection($event)"
              (click)="$event.stopPropagation()">
          <label for="item-all"
              class="pointer mb-0"
              (click)="allCheckbox.click()">{{ allSelectionText | translate }}</label>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-template ng-multi-label-tmp
      let-items="items"
      let-clear="clear">
    <ng-container *ngIf="showSelectedInBox">
      <div *ngFor="let item of items"
          class="selected-item col-auto p-base-l12">
      <div class="std-bubble"
          [ngClass]="{ 'is-invalid': invalid  &&
          (bindValue ? invalidItems.includes(item[bindValue]) : invalidItems.includes(item)) }">
          <ng-container *ngIf="showImage">
            <ng-container *ngIf="selectedChoiceImgTemp; else defaultImageTemp">
              <ng-container [ngTemplateOutlet]="selectedChoiceImgTemp"
                  [ngTemplateOutletContext]="{ item: item }">
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #defaultImageTemp>
            <img alt="profile"
                [src]="getImage(item) || defaultImage">
          </ng-template>
          <ng-container *ngIf="labelTemp; else defaultLabelTemp">
            <ng-container [ngTemplateOutlet]="labelTemp"
                [ngTemplateOutletContext]="{ item: item }">
            </ng-container>
          </ng-container>
          <ng-template #defaultLabelTemp>
            <span>{{ bindLabel != null ? getLabel(item) : item }}</span>
          </ng-template>
          <button type="button"
              class="btn-close custom-icon text-gray-pantone ms-base-l2 p-0"
              aria-label="Close"
              (mousedown)="$event.stopPropagation()"
              (click)="clear(item)">
            <svg viewBox="0 0 24 24">
              <use href="assets/images/icons/marks/x.svg#x"
                  height="24"
                  width="24">
              </use>
            </svg>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp
    let-item$="item$">
    <div class="d-flex align-items-center fw-medium text-black-deep">
      <input *ngIf="showCheckbox"
          class="form-control checkbox-default default-size me-base-l8"
          title="{{ bindLabel != null ? getLabel(item$.value) : item$.label }}"
          type="checkbox"
          [ngModel]="item$.selected">
      <ng-container *ngIf="showImage">
        <ng-container *ngIf="choiceImageTemp; else defaultImageTemp">
          <ng-container [ngTemplateOutlet]="choiceImageTemp"
              [ngTemplateOutletContext]="{ item: item$.value }">
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #defaultImageTemp>
        <img alt="profile"
            class="img-default"
            [src]="getImage(item$.value) || defaultImage">
      </ng-template>
      <div class="flex-fill-0 w-0 align-self-stretch">
        <ng-container *ngIf="choiceLabelTemp; else defaultLabelTemp">
          <ng-container [ngTemplateOutlet]="choiceLabelTemp"
              [ngTemplateOutletContext]="{ item: item$.value }">
          </ng-container>
        </ng-container>
        <ng-template #defaultLabelTemp>
          <div class="d-flex flex-column justify-content-center h-100">
            <label class="mb-0">{{ bindLabel != null ? getLabel(item$.value) : item$.label }}</label>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <ng-template ng-optgroup-tmp
      let-item="item"
      let-item$="item$"
      let-index="index">
    <div class="d-flex align-items-center fw-medium text-black-deep">
      <input *ngIf="showCheckbox"
          id="item-{{ index }}"
          class="form-control checkbox-default default-size me-base-l8"
          type="checkbox"
          [ngModel]="item$.selected">{{ getGroupLabel(item$.value) }}
    </div>
  </ng-template>
</ng-select>