import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginAdfs } from '@store/auth/auth.actions';

@Component({
  selector: 'app-adfs-callback',
  templateUrl: './adfs-callback.component.html',
  styleUrls: ['./adfs-callback.component.scss'],
})
export class AdfsCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    let data: any = null;

    try {
      const queryParams: any = this.route.snapshot.queryParams;
      let data_base64 = queryParams.data_base64;
      data_base64 = data_base64.replace(/_/g, '/');
      data_base64 = data_base64.replace(/-/g, '+');
      const data_json_str = atob(data_base64);
      data = JSON.parse(data_json_str);
    } catch (e) {
      data = null;
      this.router.navigate(['/login']);
    }

    if (data != null) {
      this.store.dispatch(new LoginAdfs(data));
    }
  }
}
