import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownPosition } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  PDFDocumentProxy,
  PdfViewerModule,
  PdfViewerComponent as Ng2PdfViewerComponent,
  ZoomScale,
} from 'ng2-pdf-viewer';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { featureFlag } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { PdfPageControllerComponent } from '../pdf-page-controller/pdf-page-controller.component';
import { ThemeService } from '@shared/service/theme.service';
import { DeviceType } from '@shared/models/common.model';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  standalone: true,
  imports: [
    AngularSvgIconModule,
    CommonModule,
    NgbDropdownModule,
    NgxSpinnerModule,
    PdfPageControllerComponent,
    PdfViewerModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent
  implements
    OnChanges,
    OnInit,
    AfterViewChecked,
    OnDestroy,
    AfterViewInit
{
  /** The bottom position in px of the controller */
  @Input() controllerBottomPosition?: string;
  @Input() page?: number | null;
  @Input() pdfFile: any;
  @Input() showDownload = false;
  @Input() pdfViewport: PdfViewport = {
    scale: 1,
    fit: 'page-fit',
  };
  @Output() downloaded = new EventEmitter<void>();
  @Output() clickPreview = new EventEmitter<void>();
  @Output() pdfViewportChange = new EventEmitter<PdfViewport>();
  @ViewChild(PdfPageControllerComponent, {
    read: ElementRef<HTMLElement>,
  })
  pdfPageControllerElRef?: ElementRef<HTMLElement>;
  @ViewChild('pdfView') pdfView: ElementRef<HTMLElement>;
  @ViewChild('pdfViewer', { static: false })
  pdfViewer: Ng2PdfViewerComponent;

  _featureFlag = featureFlag;
  _page: WritableSignal<number> = signal(1);
  compSubs: Subscription = new Subscription();
  dropdownPosition: DropdownPosition = 'auto';
  httpHeaders;
  pdfDocProxy?: PDFDocumentProxy;
  totalPage = 0;
  resize$ = new Subject<ResizeObserverEntry[]>();
  deviceType: DeviceType = 'desktop';
  tempClassForAdjusting = false;

  isLoading = true;
  lastPageRenderTime: Date;
  pageRenderInterval: any;
  latestPageRenderThreshold = 100;

  isMobile = false;
  pageOrientations: ('landscape' | 'portrait')[] = [];
  isMultipleOrientations = false;
  widestPageWidth = 0;

  private isControllerPositionChange = false;
  private cdRef = inject(ChangeDetectorRef);
  private ngxSpinner = inject(NgxSpinnerService);
  private elRef = inject(ElementRef);
  private hostObserver?: ResizeObserver;
  private themeService = inject(ThemeService);

  constructor(private authenticationService: AuthenticationService) {
    this.deviceType = this.themeService.getDeviceTypeByBreakpoint();
    this.httpHeaders = this.authenticationService.httpHeader;
    effect(() => {
      if (!this.pdfDocProxy) {
        return;
      }
      this.isLoading = true;
      this.pdfDocProxy.getPage(this._page()).then(() => {
        this.isLoading = false;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pdfFile']) {
      this.isLoading = true;
      this.clearPageRenderInterval();
      this.httpHeaders = this.authenticationService.httpHeader;
    }
    if (changes['page']) {
      const page = changes['page'].currentValue as
        | number
        | null
        | undefined;
      this._page.set(page || 1);
    }
    if (changes['controllerBottomPosition']) {
      this.isControllerPositionChange = true;
    }
  }

  ngOnInit(): void {
    this.checkWindowsWidth();
    this.ngxSpinner.show('loadingPdfViewer');
  }

  ngAfterViewInit(): void {
    this.subscribeHostResize();
  }

  ngAfterViewChecked(): void {
    if (this.isControllerPositionChange) {
      this.isControllerPositionChange = false;
      this.dropdownPosition =
        (this.pdfPageControllerElRef?.nativeElement.offsetTop || 0) >
        this.elRef.nativeElement.clientHeight / 2
          ? 'top'
          : 'bottom';
    }
  }

  ngOnDestroy(): void {
    this.compSubs.unsubscribe();
    this.clearPageRenderInterval();
  }

  @HostListener('window:resize')
  checkWindowsWidth() {
    this.isMobile = window.innerWidth < 577;
  }

  changePage(pageCount: number): void {
    const page = this._page();
    if (page == null) {
      console.warn('The current page is undefined yet');
      return;
    }
    this.toPage(page + pageCount);
  }

  onControlScaleChange(scale: number | PdfViewport['fit']): void {
    if (typeof scale === 'number') {
      this.pdfViewport.scale = scale;
      this.pdfViewport.fit = null;
    } else if (typeof scale === 'string') {
      this.pdfViewport.scale = 1;
      this.pdfViewport.fit = scale as PdfViewport['fit'];
    }
  }

  async onLoadPdfComplete(pdf: PDFDocumentProxy): Promise<void> {
    this.pdfDocProxy = pdf;
    this.totalPage = pdf.numPages;

    for (let pageNum = 1; pageNum <= this.totalPage; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const { width, height } = page.getViewport({ scale: 1 });
      if (width > this.widestPageWidth) {
        this.widestPageWidth = width;
      }
      this.pageOrientations.push(
        width / height > 1 ? 'landscape' : 'portrait',
      );
    }

    this.isMultipleOrientations =
      new Set(this.pageOrientations).size > 1;
  }

  setPageFit() {
    // Manually calculate zoom to fit the largest page dimensions
    const container = document.querySelector(
      '.pdfViewer',
    ) as HTMLElement;
    if (container) {
      const containerWidth = container.clientWidth;

      // Fit to largest page dimensions
      const widthScale = containerWidth / this.widestPageWidth;

      // Apply calculated zoom scale
      this.pdfViewport.scale = widthScale;
    }
  }

  async onPageRendered(
    e: Partial<CustomEvent> & { [k: string]: any },
  ) {
    this.pdfViewport.renderedHeight = e['source']?.viewport?.height;
    this.pdfViewport.renderedWidth = e['source']?.viewport?.width;
    this.pdfViewport.renderedScale =
      e['source']?.viewport?.scale || 1;
    this.setPdfViewerSize();

    this.lastPageRenderTime = new Date();

    if (this.isLoading && !this.pageRenderInterval) {
      this.waitAllPagesRender();
    }
  }

  /**
   * for pdfs with multiple pages, pageRendered() is called for visible pages and +1 page.
   * if ng2-pdf-viewer is visible before all these pages are rendered,
   * user will see screen flickering
   * this method triggers the ng2-pdf-viewer visibility flag
   * after making sure all necessary renderings has been done
   */
  waitAllPagesRender(): void {
    this.pageRenderInterval = setInterval(() => {
      this.checkRenderCompletion();
    }, 10);
  }

  async checkRenderCompletion(): Promise<void> {
    const now = new Date();
    const timeElapsed =
      now.getTime() - this.lastPageRenderTime.getTime();

    if (timeElapsed > this.latestPageRenderThreshold) {
      clearInterval(this.pageRenderInterval);

      if (this.isMultipleOrientations) {
        await this.setPdfOnMiddle();
        if (
          this.isMobile &&
          this.pageOrientations[0] === 'portrait'
        ) {
          this.setPageFit();
        }
      }

      this.isLoading = false;
      this.cdRef.detectChanges();
    }
  }

  clearPageRenderInterval(): void {
    this.pageRenderInterval && clearInterval(this.pageRenderInterval);
    delete this.pageRenderInterval;
  }

  /**
   * for pdf with mixed portrait and landscape pages,
   * it is not centered in compare popup for unknown reason
   * workaround fix is to set display: flex to .ng2-pdf-viewer-container
   * for a while and after a while remove the display: flex again
   */
  async setPdfOnMiddle(): Promise<void> {
    await this.delay(200);
    this.tempClassForAdjusting = true;
    this.cdRef.detectChanges();
    await this.delay(200);
    this.tempClassForAdjusting = false;
    this.cdRef.detectChanges();
  }

  private delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  toPage(page: number): void {
    if (this.isLoading) {
      return;
    }
    let finalPage = page;
    if (finalPage < 1) {
      finalPage = 1;
    } else if (finalPage > this.totalPage) {
      finalPage = this.totalPage;
    }
    this._page.set(page);
  }

  setPdfViewerSize(): void {
    this.pdfViewport.pdfViewWidth =
      this.pdfView.nativeElement.clientWidth;
    this.pdfViewport.pdfViewHeight =
      this.pdfView.nativeElement.clientHeight;
    this.pdfViewportChange.emit(this.pdfViewport);
  }

  resize(): void {
    this.deviceType = this.themeService.getDeviceTypeByBreakpoint();
  }

  subscribeHostResize(): void {
    if (!this.elRef) {
      return;
    }

    this.resize$.pipe(debounceTime(100)).subscribe({
      next: (_entries) => {
        this.pdfViewer?.updateSize();
        this.setPdfViewerSize();
        this.resize();
      },
    });
    this.hostObserver = new ResizeObserver((entries) => {
      this.resize$.next(entries);
    });
    this.hostObserver.observe(this.elRef.nativeElement);
  }
}

export interface PdfViewport {
  fit?: ZoomScale | null;
  renderedHeight?: number;
  renderedWidth?: number;
  renderedScale?: number;
  pdfViewWidth?: number;
  pdfViewHeight?: number;
  scale: number;
}
